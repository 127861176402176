import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { ShippingOptionType } from '../model/enums';
import { CreateGroupDto } from '../model/group';
import { CreateOptionDto } from '../model/option';
import { baseUrl } from './connections';
import { InitialCommercialOptions } from '../components/products/new-product/ProductComercialOptions';
import { StringArraySupportOption } from 'prettier';

// const env = process?.env?.REACT_APP_ENV || 'local';
// console.log(env);

// const urls: any = {
// 	local: 'http://localhost:3003',
// 	prod: 'https://layka-api.we-deliver.net',
// };

// export const baseUrl = urls[env];

interface LoginDto {
	email: string;
	password: string;
}

function handleError(error: any) {
	console.log(error?.response?.data);
	return { status: error?.response?.status, data: error?.response?.data };
}
function handleSuccess(response: any) {
	const data = !!response.data ? response.data : response.data;
	const responseData = parseJson(data);
	return { status: response.status, data: responseData };
}
const parseJson = (str: any) => {
	try {
		return JSON.parse(str);
	} catch (e) {
		return str;
	}
};
export async function login(user: LoginDto) {
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/user/a/signin`,
		data: user,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function whoAmI() {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		url: `${baseUrl}/user/whoami`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function deleteProduct(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/admin/product/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function disableProduct(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/admin/product/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function cloneProduct(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/product/cloneProduct/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getAllProducts(limit: any, offset: any, searchTerm: string = '', filter: string = '') {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/products?offset=${offset}&limit=${limit}&searchTerm=${searchTerm}&filter=${filter}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getCategories(limit: any, offset: any, searchQuery: string = '') {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/category/categories?offset=${offset}&limit=${limit}&searchTerm=${searchQuery}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getCategoryById(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/category/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function uploadFile(data: any | { file: File; selectable: string }) {
	const token = await localStorage.getItem('accessToken');
	const formData = new FormData();
	formData.append('file', data.file);
	formData.append('selectable', data.selectable);
	console.log('form data ', formData);
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/file/upload`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'multipart/form-data',
		},
		data: formData,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function uploadFiles(data: any | { filesArray: File[]; selectable: string }) {
	const token = await localStorage.getItem('accessToken');
	const formData = new FormData();
	data.filesArray.forEach((element: any) => {
		formData.append('files', element);
	});
	formData.append('selectable', data.selectable);
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/files/upload`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'multipart/form-data',
		},
		data: formData,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function deleteFiles(data: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/files/bulk-delete`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function fetchPDF(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/pdf/gen/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		responseType: 'blob',
	};

	try {
		const response = await axios(options);
		return response.data;
	} catch (error) {
		console.error('Error fetching PDF:', error);
		throw error;
	}
}
export async function createProduct(product: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/product/create/`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: product,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function createProductDesign(productDesign: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/productDesign`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: productDesign,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function updateProductDesign(productDesign: any) {
	const token = localStorage.getItem('accessToken');

	console.log(
		'productDesign',
		productDesign.layers.map((e: any) => e.obj.scaleX)
	);

	console.log(productDesign);

	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/productDesign`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: productDesign,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getOrders(limit: any, offset: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/orders?offset=${offset}&limit=${limit}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function deleteCategory(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/category/deleteCategory/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getProductDesigns(limit?: any, offset?: any, text?: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/productDesign/all?offset=${offset}&limit=${limit}&searchTerm=${text}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function removeProductDesign(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/productDesign?id=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getProductDesignById(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/productDesign?id=${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getProductById(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/product/by/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getCurrencies() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/currencies`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getAllCategories() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/category/allCategories`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function createCategory(categoryInfo: any) {
	const token = localStorage.getItem('accessToken');

	const options = {
		method: 'POST',
		url: `${baseUrl}/category/createCategory`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: categoryInfo,
	};

	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function updateCategory(id: string, categoryInfo: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/category/editCategory/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: categoryInfo,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function disableCategory(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/category/disableCategory/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function createSeo(seoInfo: any) {
	const token = localStorage.getItem('accessToken');

	const options = {
		method: 'POST',
		url: `${baseUrl}/seo`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: seoInfo,
	};

	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getSeoById(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/seo/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function updateSeo(id: string, seoInfo: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/seo/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: seoInfo,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export const getImage = (imageId: string) => `${baseUrl}/product/file/${imageId}`;
export const getFontFile = (imageId: string) => `${baseUrl}/layout/font-file/${imageId}`;

export async function createSymbol(product: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/symbols/create`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: product,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function updateSymbol(product: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/symbols/edit`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: product,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getSymbols() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/symbols`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function deleteSymbolById(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/admin/symbols/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function createFont(font: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/layout/fonts/create`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: font,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function updateFont(font: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/layout/fonts/edit`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: font,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getFonts() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/layout/fonts`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getFontsPagination(limit?: any, offset?: any, text?: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/layout/all-fonts/pagination?offset=${offset}&limit=${limit}&searchTerm=${text}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getSymbolsPagination(limit?: any, offset?: any, text?: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/all-symbols/pagination?offset=${offset}&limit=${limit}&searchTerm=${text}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function deleteFontById(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/layout/fonts/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function uploadFontFile(file: any) {
	const token = await localStorage.getItem('accessToken');
	const formData = new FormData();
	formData.append('file', file);
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/file/upload`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'multipart/form-data',
		},
		data: formData,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function createCountries(list: any[]) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/location/countries`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: list,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getCountries() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/location/countries`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getRegions() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/location/regions`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getMunicipalities() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/location/municipalities`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getCities() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/location/cities`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function createZone(data: { zone_name: string; countries: any[] }) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/location/zone`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function editZone(data: { id: string; zone_name: string; countries: any[] }) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/location/zone/update`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getZones() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/location/zones`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getZoneById(id: string | undefined) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/location/zone/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function deleteZoneById(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/location/zone/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getAllLanguages() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/language/all`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getAllTopCategoriesByLanguage(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/categories/by/language/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getCategoriesChildren(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/categories/children/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getLanguages(offset: any, limit: any, searchQuery: string = '') {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/language?offset=${offset}&limit=${limit}&searchTerm=${searchQuery}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getLanguageById(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/language/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function createLanguage(categoryInfo: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/language/createLanguage`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: categoryInfo,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function updateCategoriesOrder(data: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/categories/update/order`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function editLanguage(categoryInfo: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/language/editLanguage/`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: categoryInfo,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function editLanguageOrder(languageOrder: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/language/languageOrder`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: languageOrder,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function deleteLanguage(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/language/deleteLanguage/${id}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getCommercialGroups(productId: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/product/commercial-groups?product=${productId}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getCommercialGroupsOptions(groupId: string, single: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/product/commercial-options?group=${groupId}&single=${single}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getOptionsMap(groupId: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/product/option-maps?option=${groupId}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function insertProductToMap(data: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/product/option-map`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function createOption(data: CreateOptionDto) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/product/commercial-option`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function deleteGroupOption(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/admin/product/commercial-option/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function createGroup(data: CreateGroupDto) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/product/commercial-group`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function deleteGroupById(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/admin/product/commercial-group/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function createShippingOption(data: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/location/shippingOption`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function editShippingOption(data: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/location/shippingOption/edit`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function updateShippingOptionsList(data: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/location/shippingOptions/update`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function deleteShippingOptionById(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/location/shippingOption/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getCustomers(limit: any, offset: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/customers/all?offset=${offset}&limit=${limit}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function editCustomer(id: any, customerInfo: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/customers/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: customerInfo,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function createAddress(info: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/address/`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: info,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function editAddress(id: string, addressInfo: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/address/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: addressInfo,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function insertProduct(data: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/product/create`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function updateProduct(data: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/product/update`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getSsp() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/ssp/`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function createSsp(info: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/ssp/`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: info,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function editSsp(id: string, info: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/ssp/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: info,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getCompanyInformation() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/companyInfo/`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function createCompanyInformation(info: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/companyInfo`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: info,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function editCompanyInformation(id: string, info: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/companyInfo/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: info,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function createAllowedDesigns(data: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/allowed-designs`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getSliders() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/slider/`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function createSlider(info: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/slider`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: info,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function updatedAddress(b: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/customers/address/update`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: b,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function deleteCustomer(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/admin/customers/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function deleteAllowedDesignById(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/admin/allowed-designs/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function createAllowedFonts(data: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/allowed-fonts`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function deleteAllowedFontsById(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/admin/allowed-fonts/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function createAllowedSymbols(data: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/allowed-symbols`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function deleteAllowedSymbolById(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/admin/allowed-symbols/${id}`,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function editSlider(id: any, info: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/slider/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: info,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getSliderById(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/slider/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function deleteSlider(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/slider/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getAvailableAllowedDesign(productId: string, limit?: any, offset?: any, text?: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/allowed-designs/available?productId=${productId}&offset=${offset}&limit=${limit}&term=${text}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getAllowedDesign(productId: StringArraySupportOption) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/allowed-designs?productId=${productId}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getAvailableAllowedSymbols(productId: string, limit?: any, offset?: any, text?: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/allowed-symbols/available?productId=${productId}&offset=${offset}&limit=${limit}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getAllowedSymbols(productId: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/allowed-symbols?productId=${productId}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getAvailableAllowedFonts(productId: string, limit?: any, offset?: any, text?: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/allowed-fonts/available?productId=${productId}&offset=${offset}&limit=${limit}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getAllowedFonts(productId: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/allowed-fonts?productId=${productId}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getAdminProductById(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/product/by/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function editSliderOrder(info: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/slider/`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: info,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getPaymentTypes() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/payment/`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getPaymentTypeById(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/payment/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function editPaymentTypeOrder(info: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/payment/paymentTypeOrder/`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: info,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function editPaymentType(id: any, info: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/payment/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: info,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function createPaymentType(info: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/payment/`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: info,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function deletePaymentType(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/payment/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function disablePaymentType(info: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/payment/disablePaymentType/`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: info,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getAllOrders(term: string, limit: any, offset: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/order/all?term=${term}&limit=${limit}&offset=${offset}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getOrderById(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/order/by/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getAllOrderStatuses() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/order/status`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function createOrderComment(info: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/order/comment`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: info,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function deleteOrderComment(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/admin/order/comment/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getOrderItemsByOrderId(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/order/items/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function createOrderStatusLog(info: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/order/status/log`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: info,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function editOrderStatus(id: any, info: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/admin/order/status/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: info,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function deleteOrderItem(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/admin/order/items/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function deleteOrder(id: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/admin/order/by/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function createProductCommercialOptions(data: InitialCommercialOptions) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/product/commercial-options/update`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function changeDefaultShippingAddress(customerId: any, addressId: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/customers/address/change_default_shipping_address?customer_id=${customerId}&address_id=${addressId}`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function changeDefaultBillingAddress(customerId: any, addressId: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/customers/address/change_default_billing_address?customer_id=${customerId}&address_id=${addressId}`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getGallery(limit?: any, offset?: any, term?: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/gallery?term=${term}&limit=${limit}&offset=${offset}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function getSymbolCategory() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/symbol/categories`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function createSymbolCategory(data: any) {
	const token = await localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/admin/symbol/category/insert`,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		data: data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getAllFonts() {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/product/fonts/all`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function getSymbolById(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/symbols/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function exportProducts(filter: string = '') {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/export?filter=${filter}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
export async function checkProductSku(sku?: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'GET',
		url: `${baseUrl}/admin/check-sku?sku=${sku}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function deleteSymbolCategoryById(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/admin/symbol/category/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function createQAPair(data: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'POST',
		url: `${baseUrl}/ssp/Q&A`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function editQAPair(data: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/ssp/Q&A`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function deleteQAPair(id: string) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'DELETE',
		url: `${baseUrl}/ssp/Q&A/${id}`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}

export async function editQAPairOrder(info: any) {
	const token = localStorage.getItem('accessToken');
	const options: AxiosRequestConfig = {
		method: 'PUT',
		url: `${baseUrl}/ssp/Q&A/order`,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		data: info,
	};
	return axios(options)
		.then((response) => handleSuccess(response))
		.catch((error) => handleError(error));
}
